<template>
  <div class='pb80 pt120'>

    <div class="flex col-center row-column">
      <img :src="imgD" class="">
      <div class="back_btn pointer" @click="$router.back()">返回列表</div>
    </div>
  </div>
</template>

<script>
import { getSolutionDetails } from '@/api'
export default {
  data () {
    return {
      imgD: ''
    };
  },

  components: {},

  created () {
    this.getSolutionDetails(this.$route.params.id)
  },

  mounted () { },

  methods: {
    async getSolutionDetails (id) {
      const res = await getSolutionDetails({ id })
      this.imgD = res.data.pcPic
      console.log(111, res);
    }
  }
}
</script>
<style lang='scss' scoped>
.back_btn {
  width: 278px;
  height: 94px;
  background: #272727;
  border: 2px solid #272727;
  font-size: 32px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 94px;
  text-align: center;
}
</style>
